import React from "react"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.en"

const IndexEN = ({ location }) => {
  return (
    <>
      <Metatags
        title="Multilógica | Elektroteks Acquires Mammut"
        description="Mattress machinery supplier"
        url="https://newsite.multilogica.ind.br/en/privacy-policy"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="mattress, sewing machine, mammut, beckmann sew"
        language="en"
      />
      <NavBar location={location["pathname"]} />
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mx-2">
          <div className="col-span-2" />
          <div className="col-span-8 text-center">
            <div className="text-5xl font-semibold text-mi-blue">
              A Elektroteks Adiquire A Mammut
            </div>
            <div className=" h-8" />
            <div className=" text-left text-xl">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                <b>
                  Bursa, Turquia / Colônia, Alemanha - [2 de outubro de 2024]
                </b>{" "}
                - A Elektroteks, a maior fabricante mundial de máquinas para
                colchões e espumas, tem o orgulho de anunciar a aquisição de
                100% das ações da Mammut (Nähmaschinenfabrik Emil Stutznäcker
                GmbH & Co KG), líder mundial na fabricação de máquinas de
                acolchoamento. Com mais de 140 anos de história, a Mammut é
                conhecida por produzir as máquinas de acolchoamento da mais alta
                qualidade, estabelecendo o padrão de precisão e artesanato na
                indústria. O negócio foi oficialmente fechado em 23 de setembro.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Elektroteks</b>, com sede em Bursa, Turquia, é uma empresa
                familiar com presença global em 133 países. Como maior produtora
                de máquinas para as indústrias de colchões e espumas, a
                Elektroteks construiu uma reputação de inovação, satisfação do
                cliente e uma robusta rede de serviços globais. Com 350
                funcionários e uma moderna instalação de produção de 65.000 m²,
                a empresa continua a oferecer soluções líderes na indústria.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Mammut</b>, com sede em Colônia, Alemanha, está na vanguarda
                da tecnologia de acolchoamento há mais de um século, fabricando
                as máquinas de acolchoamento mais precisas do mundo. A
                Elektroteks está totalmente comprometida em preservar esse
                patrimônio enquanto infunde nova energia e ideias na marca
                Mammut.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Michael Pavlidis permanecerá como Diretor Geral da Mammut</b>
                , garantindo continuidade e liderança durante este novo e
                empolgante capítulo. Sua experiência e dedicação serão cruciais
                para conduzir a empresa através de projetos e inovações futuros.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Serkan Guler, CEO da Elektroteks</b>, declarou: “Esta
                aquisição é uma combinação poderosa de dois líderes da
                indústria. Estamos honrados em continuar o legado da Mammut, o
                nome mais antigo e respeitado em máquinas de acolchoamento, e
                esperamos colaborar em novos projetos que trarão soluções
                inovadoras ao mercado.”
              </p>
              <p>&nbsp;</p>
              <p>
                Como parte desta integração, a Elektroteks expandirá as
                operações de peças de reposição e serviços da Mammut usando sua
                extensa rede global. A sede e a produção da Mammut permanecerão
                na Alemanha, preservando seu forte patrimônio. Novos projetos
                empolgantes já estão em andamento, prometendo aprimorar ainda
                mais as ofertas da Mammut na indústria de máquinas de
                acolchoamento.
              </p>
              <p>&nbsp;</p>
              <p>
                A aquisição da Mammut pela Elektroteks anuncia uma nova era de
                crescimento e inovação, aproveitando as forças de ambas as
                empresas para fornecer produtos e serviços superiores às
                indústrias globais de colchões e espumas.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Para mais informações</b>, por favor contate:
                <br />
                info@elektroteks.com
                <br />
                sales@mammut.de
              </p>
            </div>
          </div>
        </div>
        <div className="h-10" />
      </div>
      <div className="h-20" />
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default IndexEN
